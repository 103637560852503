/**
 * Ui and Strapi data types
 */

export type Locale = 'en' | 'el';

export type token = '';

export type Stage = {
  message: string;
  keys: {
    clientId: string;
    clientSecret: string;
  };
  partnerId: string;
  webhookSecret: string;
};

export type Prod = {
  message: string;
  keys: {
    clientId: string;
    clientSecret: string;
  };
  partnerId: string;
  webhookSecret: string;
};

export type OBF = {
  name: string;
  date: string;
  vat: number;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  affiliate: string;
  affiliationEmail: string;
  packageSize: PackageSize;
  shippingLabelInfo: string;
  warehouseName: string;
  warehouseAddress: string;
  warehouseCity: string;
  warehouseZip: string;
  warehouseCountry: string;
  depotId: string | null;
  iban: string;
  latitude: string;
  longitude: string;
  boxnowLockerId: string;
};

export type OBFResponse = {
  stage: Stage;
  prod: Prod;
};

export type Depot = {
  id: string;
  name: string;
};

export enum PackageSize {
  None = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
}

export type HeaderOption = {
  title: string;
  link: string;
  group: string;
  order: number;
  key: number;
};

export type FooterOption = {
  title: string;
  link: string;
  group: string;
  order: number;
  key: number;
};

export type FooterIconOption = {
  link: string;
  icon: string;
};
